import Modal, { ModalMixin } from "@/components/modals/layouts/Default.vue";
export default {
  mixins: [ModalMixin],
  data() {
    return {
      withdrawal: null
    };
  },
  mounted() {},
  methods: {
    async onOpen({
      withdrawal
    }) {
      this.withdrawal = withdrawal;
    }
  },
  computed: {
    actions() {
      return [{
        name: "submit",
        text: "ОК",
        action: ({
          close
        }) => {
          close();
        }
      }];
    }
  },
  components: {
    Modal
  }
};