var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('modal', {
    ref: "modal",
    attrs: {
      "title": "Вывод средств",
      "width": 600,
      "actions": _vm.actions
    }
  }, [_c('p', [_vm._v("Спасибо, заявка на вывод успешно оформлена!")]), _c('p', [_vm._v(" В ближайшее время мы свяжемся с вами для подтверждения реквизитов."), _c('br'), _vm._v("В рабочее время это не займет больше часа. ")]), _c('p', [_vm._v("ID вашей заявки — " + _vm._s(_vm.withdrawal && _vm.withdrawal.id))])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };